import { alpha, Box, BoxProps, ButtonBase, CircularProgress, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SendSvg from 'Assets/icons/send.svg';
import clsx from 'clsx';
import PgIcon from 'Components/PgIcon';
import TextFieldWMention from 'Components/TextFieldWMention';
import { striptedData, textFieldWMentionHelpers } from 'Components/TextFieldWMention/helpers';
import { MAX_FILE_SIZE } from 'Features/ReactFormFields/ImagePicker';
import useShowToast from 'Hooks/useShowToast';
import PictureModel from 'Models/Picture';
import { Picture } from 'Models/Picture/@types';
import React, { FC, ReactNode, useContext, useMemo, useRef, useState } from 'react';
import { BORDER_BLACK, COLOR } from 'Theme/themeConstants';
import helpers from 'Utils/helpers';
import { TDiscussionSendMessageFn } from './useDiscussion';
import { ChatContext } from 'Contexts/ChatContext';


const IMAGE_ACCEPT_TYPE = 'image/gif, image/jpg, image/jpeg, image/png';

export interface IRenderActionButtons {
    editedMessageText: string;
    images: Array<Picture | { b64: string, isLoading: boolean }>
}
export interface ChatInputProps extends BoxProps {
    parentMessageId?: string /** this will be present if input field is used for reply */;
    sendButtonClassName?: string;
    sendMessage: TDiscussionSendMessageFn;
    isDisabled?: boolean;
    rows?: number;
    discussionId: string;
    currValue?: string;
    renderActionButtons?: (props: IRenderActionButtons) => ReactNode;
    pictures?: Picture[];
}

const ChatInput: FC<ChatInputProps> = (props) => {
    const classes = useStyles();
    const { sendMessage, parentMessageId, className, isDisabled, sendButtonClassName, rows = 3, discussionId, currValue, renderActionButtons, pictures, ...boxProps } = props;
    const { isAdmin } = useContext(ChatContext);
    const [currMessage, setCurrMessage] = useState<string>(currValue ?? '');
    const [images, setImages] = useState<Array<Picture | { b64: string, isLoading: boolean }>>(pictures ?? [])
    const [error, setError] = useState<string>();
    const handleSubmit = () => {
        const val = ``.concat(currMessage);
        if (currMessage && val.replace(/\s/g, '')) {
            const parsedMentionMessage = val.split(' ').map(
                (m) =>
                    new Promise((res) => {
                        const isMention = /\B@[a-z0-9_-]+/gi.test(m);
                        if (!isMention) res(m);
                        const mention = m.slice(1);
                        textFieldWMentionHelpers.fetchAutocomplete(discussionId, mention, (data) => {
                            if (!data || !data.length || data?.[0]?.display !== mention) res(m);
                            res(striptedData(data?.[0]?.display ?? '', data[0]?.id as string));
                        });
                    }),
            );
            Promise.all(parsedMentionMessage).then((val) =>
                sendMessage(
                    textFieldWMentionHelpers.stripMetaData(val.join(' '), ['profileImg']),
                    parentMessageId,
                    images.filter(f => (f as Picture).url) as Picture[],
                )
                    .then(() => setError(undefined))
                    .catch(() => setError('Message was not sent successfully.')),
            );
        } else if (images.length) {
            sendMessage(
                currMessage,
                parentMessageId,
                images.filter(f => (f as Picture).url) as Picture[],
            )
                .then(() => setError(undefined))
                .catch(() => setError('Message was not sent successfully.'))
        }
        setCurrMessage('');
        setImages([]);
    };

    const inputRef = useRef<HTMLInputElement>(null);

    const showToast = useShowToast();

    const handleChange = async (event: any) => {
        const { files } = event.target;
        try {
            await Promise.all([...files].map(file => new Promise((res, rej) => {
                if ((file.size / 1024) > MAX_FILE_SIZE) {
                    showToast('Oops! Choose an image less than 10MB!', undefined, 'error');
                    rej(`File too large: ${file}`);
                }
                const reader = new FileReader();
                reader.onload = async () => {
                    let index: number;
                    setImages(i => {
                        index = i.length
                        return [...i, { b64: reader.result as string, isLoading: true }]
                    })
                    const image = await PictureModel.upload({ base64: reader.result as string, name: file.name });
                    setImages(i => [...i.slice(0, index), image, ...i.slice(index + 1)]);
                    res(true);
                };
                reader.readAsDataURL(file);
            })))
        } catch (error) {
            console.error(error);
        }
    }

    const handleFileUploadClick = () => {
        inputRef.current?.click();
    }

    const handleImageClick = (index: number) => () => {
        setImages(i => i.filter((_, i) => i !== index));
    }

    const isLoading = useMemo(() => images.reduce((prev, curr) => prev || !!(curr as any)?.isLoading, false), [images]);

    return (
        <Box className={clsx(classes.container, className)} {...boxProps}>
            <Box display='flex' flexDirection={"column"} flexGrow={1} alignItems='flex-start'  >
                <Box display='flex' width={"100%"} flexDirection={"row"} gridGap={12}>
                    {isAdmin && (
                        <Box className={classes.imageInputWrap} component={ButtonBase} onClick={handleFileUploadClick}>
                            <PgIcon icon='icon-plus' styleClass={classes.icon} />
                            <input ref={inputRef} type="file" multiple accept={IMAGE_ACCEPT_TYPE} onChange={handleChange} className={classes.imageInput} />
                        </Box>
                    )}
                    <TextFieldWMention
                        fetchSuggestions={(q, c) => textFieldWMentionHelpers.fetchAutocomplete(discussionId, q, c)}
                        onChange={(e) => setCurrMessage(e.target.value)}
                        value={currMessage || ''}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit();
                            }
                        }}
                        placeholder="Type your message here"
                        disabled={isDisabled}
                        className={clsx(classes.input)}
                        helperText={error}
                        error={!!error}
                    />
                </Box>
                <Box>
                    {!!images.length && (
                        <Box display='flex' alignItems='center' gridGap={8} mt={1}>
                            {images.map((img, idx) => {
                                const imgSrc = (img as any)?.b64 ?? helpers.getPictureUrl(img as Picture);
                                const imgName = (img as Picture).name ?? 'loading-image';
                                const isLoading = !!(img as any)?.isLoading;
                                return (
                                    <Box
                                        key={idx}
                                        component={ButtonBase}
                                        className={classes.imgThumbWrap}
                                        onClick={isLoading ? undefined : handleImageClick(idx)}
                                        style={{ cursor: isLoading ? 'default' : undefined }}
                                    >
                                        <img src={imgSrc} alt={imgName} className={classes.imgThumb} />
                                        <Box id='imageThumbOverlay' className={classes.closeIconWrap} style={{ display: isLoading ? 'grid' : undefined }}>
                                            {isLoading ? <CircularProgress size={10} /> : <PgIcon icon='icon-close' styleClass={classes.icon} />}
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                    )}
                </Box>
            </Box>
            {
                !!renderActionButtons ? renderActionButtons({ editedMessageText: currMessage, images }) :
                    <IconButton
                        disabled={isDisabled || isLoading}
                        className={clsx(classes.sendButton, sendButtonClassName, { [classes.active]: !!currMessage, [classes.errorSendButton]: !!error })}
                        onClick={handleSubmit}
                    >
                        <img src={SendSvg} height="20px" />
                        {/* <ReactSVG /> */}
                    </IconButton>
            }
        </Box>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        container: {
            position: "relative",
            width: '100%',
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 4,
            padding: theme.spacing(1.5, 1.75),
            border: BORDER_BLACK,
            backgroundColor: theme.palette.common.white,
        },
        active: {
            '& svg': {
                fill: `${theme.palette.secondary.main} !important`,
            },
            '& path': {
                fill: `${theme.palette.secondary.main} !important`,
                fillOpacity: `1 !important`,
            },
        },
        input: {
            // background: '#fff',
            // borderRadius: 4,
            // padding: theme.spacing(1.5, 1.75),
            // paddingRight: 45,
            // '& textarea': {
            //     borderRadius: 4,
            //     padding: theme.spacing(1.5, 1.75),
            // },
            '& textarea, & textarea:focus': {
                border: 'none',
                outline: 'none',
            },
        },
        errorSendButton: {
            bottom: `24px !important`,
        },
        sendButton: {
            width: "auto",
            // position: 'absolute',
            // right: theme.spacing(0),
            // bottom: "10px",
            // marginLeft: "12px",
            // padding: theme.spacing(1, 1.6),
        },
        // outline: {
        //     border: `1px solid rgba(0,0,0,0.5)`,
        //     '& hover': {
        //         border: `1px solid rgba(0,0,0,0.5)`,
        //         borderColor: 'rgba(0,0,0,0.5)',
        //     },
        //     '& focus': {
        //         border: `1px solid rgba(0,0,0,0.5)`,
        //         borderColor: 'rgba(0,0,0,0.5)',
        //     },
        // },
        imageInputWrap: {
            backgroundColor: COLOR.tertiary.music,
            width: theme.spacing(4),
            minWidth: theme.spacing(4),
            height: theme.spacing(4),
            display: 'grid',
            placeItems: 'center',
        },
        icon: {
            fontSize: theme.spacing(1),
            WebkitTextStrokeWidth: '1px',
        },
        imageInput: { display: 'none' },
        imgThumbWrap: {
            minWidth: theme.spacing(4),
            height: theme.spacing(4),
            position: 'relative',
            '&:hover #imageThumbOverlay': { display: 'grid' },
        },
        imgThumb: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        closeIconWrap: {
            position: 'absolute',
            inset: 0,
            zIndex: 5,
            backgroundColor: alpha(COLOR.tertiary.music, 0.4),
            display: 'none',
            placeItems: 'center',
        },
    });
});

export default ChatInput;
