/* eslint-disable camelcase */
import { createStyles, makeStyles, Portal, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { generatePath, useHistory, useLocation } from 'react-router';
import PgButton from 'Components/PgButton';
import PgDialog from 'Components/PgDialog';
import PgTypo from 'Components/PgTypo';
import CustomIcon from 'Components/SvgIcon';
import { AppDialogContext } from 'Contexts/AppDialogContext';
import useTicketingDialog from 'Dialogs/Ticketing/useTicketingDialog';
import PasswordForm from 'Features/Form/PasswordForm';
import useUserActions from 'Features/User/useUserActions';
import { TContentType, TDrop } from 'Models/Drop/@types';
import { TEvent } from 'Models/Event/@types';
import { TResource } from 'Models/Resource/@types';
import { TUserFollows, User } from 'Models/User';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useStoreActions, useStoreDispatch, useStoreState } from 'Stores';
import DropUtils, { DropActionType } from 'Utils/DropUtils';
import helpers from 'Utils/helpers';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import NFTDetailsSlider from 'Features/Auth/NFTDetailsSlider';
import Spacer from 'Components/Spacer';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';
import { EGateType } from 'Models';
import { ANALYTICS_USER_EVENT } from 'Analytics/analyticActions';
import BasicActionDialog from 'Dialogs/BasicActionDialog';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import EventModel from 'Models/Event';
import { useLivepeerVideoPlayer } from 'Features/LivePeer/VideoPlayer';
import { isEvent } from 'Models/Event/utils';
import utils from 'Utils';
import { VIDEO_PAGE_PATH } from 'Screens/ResourcesPage';
import useRSVPDialog from 'Dialogs/RSVP/useRSVPDialog';
import { getResourcePath } from './DropLink';
export type DropActionButtonType = 'primary' | 'secondary';

export interface IDropActionButtonProps {
    drop: TDrop;
    resourceType: TContentType;
    type?: DropActionButtonType;
    onActionComplete?: () => void;
    isDisabled?: boolean;
    startOnLoad?: boolean;
    onVideoUnlocked?: (password?: string, isNFTGateUnlocked?: boolean) => void;
    RSVPDrops?: (doctype: TContentType, subjectId: string, userId: string, password?: string, showToast?: boolean, shouldRefresh?: boolean) => Promise<TUserFollows<User> | undefined>;
}

export const DROP_CARD_BTN_ID = 'drop-card-action-button-id';

const DropActionButton: FC<IDropActionButtonProps> = (
    { drop, resourceType: _resourceType, type = 'secondary', onActionComplete, isDisabled = false, startOnLoad, onVideoUnlocked, RSVPDrops }
) => {
    const resourceType = _resourceType === 'Event' ? _resourceType : _resourceType.toLowerCase() as TContentType;
    const classes = useStyles({ type });
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({
        appUser,
    }));

    const { pathname } = useLocation();

    const dispatch = useStoreDispatch();

    const { isDeviceSm } = useMediaQuery();

    const isFavourited = pathname.endsWith('/favorite');

    const { setDropList } = useStoreActions(({ DropsStore: { setDropList } }) => ({ setDropList }));
    const { setFavouriteEvents, fetchMe } = useStoreActions(({ UserFavouriteEventsStore: { setFavouriteEvents }, AuthStore: { fetchMe } }) => ({
        setFavouriteEvents,
        fetchMe,
    }));
    const { drops } = useStoreState(({ DropsStore: { drops } }) => ({ drops }));
    const { favouriteEvents } = useStoreState(({ UserFavouriteEventsStore: { favouriteEvents } }) => ({ favouriteEvents }));
    const { openTicketingDialog, Component: TicketingDialog } = useTicketingDialog((isSoldOut) => {
        setUpdatedDropActionType(isSoldOut ? 'SOLD_OUT' : 'GOING');
        onActionComplete?.();
        dispatch({
            type: ANALYTICS_USER_EVENT,
            data: {
                eventName: 'DROP_REMINDER',
                context: { user: appUser, drop, community: drop.community, communityId: drop.community?.id ?? drop.communityId },
                source: pathname,
            },
        });
    });
    const { showDialog, hideDialog } = useContext(AppDialogContext);

    const { openDialog: openAuthDialog } = useAuthDialog();

    const [updatedDropActionType, setUpdatedDropActionType] = useState<DropActionType>();

    const { optOut, isUserEligible, doAction } = useUserActions();

    const dropActionType = updatedDropActionType ?? DropUtils.getDropActionType(resourceType ?? 'collect', drop);
    const buttonText = DropUtils.getButtonText(resourceType ?? 'collect', dropActionType);
    const { id } = drop;
    const handleTicketing = useCallback(
        async (password?: string) => {
            openTicketingDialog(drop as TEvent, password);
        },
        [drop as TEvent, openTicketingDialog],
    );

    const removeRSVP = async () => {
        return new Promise((resolve, reject) => {
            showDialog(
                <PgDialog
                    heading="Are you sure you want to remove this reminder?"
                    positiveText="REMOVE"
                    negativeText="CANCEL"
                    handleFailure={async () => {
                        reject();
                        hideDialog();
                    }}
                    handleSuccess={async () => {
                        await doAction(optOut, [drop.reminderId as string], ['VERIFY_EMAIL']);
                        resolve({});
                        if (isFavourited) {
                            setFavouriteEvents({
                                action: 'SET',
                                list: helpers.updateItemList(favouriteEvents, { ...drop, reminderId: undefined, isReminded: false }, 'UPDATE'),
                            });
                        } else {
                            setDropList({ drop: helpers.updateItemList(drops, { ...drop, reminderId: undefined, isReminded: false }, 'UPDATE') });
                        }
                        hideDialog();
                        onActionComplete?.();
                    }}
                />,
                {
                    isActionCloseButton: false,
                    headerProps: {
                        headerClasses: classes.dialogHeader,
                    },
                    contentProps: {
                        style: { padding: '8px 24px' },
                    },
                    PaperProps: {
                        style: {
                            maxWidth: 460,
                            height: 180,
                        },
                    },
                },
            );
        });
    };

    const [showPasswordDialog, setShowPasswordDialog] = useState(false);

    const handlePasswordSubmit = (password: string) => {
        setShowPasswordDialog(false);
        (drop as TEvent).isDropUnlocked = true;
        if (resourceType === 'Event') handleEventClick(password);
        else handleResourceClick(password);
    };

    const PasswordDialog = (
        <BasicActionDialog
            onClose={() => setShowPasswordDialog(false)}
            open={showPasswordDialog}
            dialogProps={{ fullScreen: false }}
            additionalComponent={
                <PgDialog
                    heading="You need a password to unlock this drop."
                    subheading="No password? Contact the creator to get access."
                    body={<PasswordForm afterSubmit={handlePasswordSubmit} id={id} dropType={resourceType} />}
                    actionButtons={true}
                />
            }
            dialogMaxWidth={isDeviceSm ? ('unset' as unknown as number) : 600}
        />
    );

    const handleUnlock = () => {
        const isEvent = EventModel.isEvent(drop);

        openAuthDialog(appUser?.publicAddress ? 'unlock-gated' : 'wallet-select', {
            heading: `Unlock ${isEvent ? drop.name : drop.title}`,
            subtitle: '',
            body: (
                <>
                    <NFTDetailsSlider nftGates={drop.nftGates ?? []} />
                    <Spacer height={20} />
                    <PgTypo b4>In order to continue, you must connect the wallet where you own the token.</PgTypo>
                </>
            ),
            variant: 'small',
            isGatedDrop: true,
            drop,
            resourceType,
            onDropUnlocked: () => {
                drop.isDropUnlocked = true;
                handleEventClick();
            },
        }).then(() => {
            if (!appUser?.publicAddress) fetchMe();
        });
    };

    const handleRSVPDone = (drop: TDrop, userId: string, password?: string) => {
        if (DropUtils.isEventMoment(resourceType ?? drop.doc_type ?? 'collect')) handleEventClick(password, true, userId);
        else handleResourceClick(password, true, userId)
    }

    const { openDialog, component: RSVPComponent } = useRSVPDialog(handleRSVPDone);

    const handleEventClick = async (password?: string, fromLogOutRsvp = false, userId?: string) => {
        const event = drop as TEvent;

        let isEligible = true;
        if (dropActionType === 'RSVPD') {
            isEligible = await isUserEligible(['VERIFY_EMAIL']);
            if (!isEligible) return;
        }

        if (event.gateType === EGateType.NFT_GATED && (!event.isDropUnlocked || !appUser?.publicAddress)) {
            handleUnlock();
            return;
        }

        if (event.gateType === EGateType.PASSWORD_PROTECTED && dropActionType !== 'RSVPD' && !password) {
            // requiredPassword();
            setShowPasswordDialog(true);
            return;
        }

        if (dropActionType === 'RSVP' && !appUser?.id && !fromLogOutRsvp) {
            openDialog({ ...event, doc_type: resourceType, password });
            return
        }

        if (dropActionType === 'GOING' || dropActionType === 'PURCHASE_TICKET' || dropActionType === 'REGISTER' || dropActionType === 'JOIN_NOW') {
            handleTicketing(password);
            return;
        }

        // if (dropActionType === 'JOIN_NOW') window.open((drop as TEvent).platform.link, '_blank', 'noopener=true noreferrer=true');
        if (dropActionType === 'RSVP') {
            const rsvpUrl = (drop as TEvent)?._tiers?.find((t) => t?.type === (drop as TEvent)?.tierType)?.ticketUrl;
            if (rsvpUrl) {
                const windowProxy = window.open(rsvpUrl, '_blank');
                if (windowProxy === null) {
                    alert('A new tab has been opened but your browser seems to have blocked it. Please check blocked popups');
                }
            }
            // const res: TUserFollows<User> | undefined = await doAction(RSVP, [resourceType, drop.id, appUser?.id as string, password], ['VERIFY_EMAIL']);
            const res = await RSVPDrops?.(resourceType, drop.id, userId ?? appUser?.id ?? '', password, true, false);
            setUpdatedDropActionType('RSVPD');
            if (res) {
                if (isFavourited) {
                    setFavouriteEvents({
                        action: 'SET',
                        list: helpers.updateItemList(favouriteEvents, { ...drop, reminderId: res?.id, isReminded: true }, 'UPDATE'),
                    });
                } else {
                    setDropList({ drop: helpers.updateItemList(drops, { ...drop, reminderId: res?.id, isReminded: true }, 'UPDATE') });
                }
                onActionComplete?.();
                dispatch({
                    type: ANALYTICS_USER_EVENT,
                    data: { eventName: 'DROP_REMINDER', context: { user: appUser, drop, community: drop.community }, source: pathname },
                });
            }
            return;
        }
        if (dropActionType === 'RSVPD') {
            removeRSVP().then(() => setUpdatedDropActionType('RSVP'));
        }
    };

    const { openVideoPlayer, player: VideoPlayer } = useLivepeerVideoPlayer();

    const history = useHistory();

    const handleResourceClick = async (password?: string, fromLogOutRsvp = false, userId?: string) => {

        // if (resourceType === 'video' && !isEvent(drop) && drop.playbackId && dropActionType === 'LINK') {
        //   const videoPagePath = generatePath(VIDEO_PAGE_PATH, { id: drop.slug ?? drop.id ?? '#' })
        //   if (pathname !== videoPagePath) {
        //     history.push(videoPagePath);
        //     return;
        //   }
        // }

        let isEligible = true;
        if (dropActionType === 'REMINDER_SET') {
            isEligible = await isUserEligible(['VERIFY_EMAIL']);
            if (!isEligible) return;
        }

        if (drop.gateType === EGateType.NFT_GATED && (!drop.isDropUnlocked || !appUser?.publicAddress)) {
            handleUnlock();
            return;
        }
        if (drop.gateType === EGateType.PASSWORD_PROTECTED && dropActionType !== 'REMINDER_SET' && !password) {
            // requiredPassword();
            setShowPasswordDialog(true);
            return;
        }

        if (dropActionType === 'REMIND_ME' && !appUser?.id && !fromLogOutRsvp) {
            openDialog({ ...drop, doc_type: resourceType, password });
            return
        }

        if (dropActionType === 'REMIND_ME') {
            // const res: TUserFollows<User> | undefined = await doAction(RSVP, [resourceType, drop.id, appUser?.id as string, password], ['VERIFY_EMAIL']);
            const res = await RSVPDrops?.(resourceType, drop.id, userId ?? appUser?.id as string, password, true, false)
            setUpdatedDropActionType('REMINDER_SET');
            if (res) {
                if (isFavourited) {
                    setFavouriteEvents({
                        action: 'SET',
                        list: helpers.updateItemList(favouriteEvents, { ...drop, reminderId: res?.id, isReminded: true }, 'UPDATE'),
                    });
                } else {
                    setDropList({ drop: helpers.updateItemList(drops, { ...drop, reminderId: res?.id, isReminded: true }, 'UPDATE') });
                }
                dispatch({
                    type: ANALYTICS_USER_EVENT,
                    data: { eventName: 'DROP_REMINDER', context: { user: appUser, drop, community: drop.community }, source: pathname },
                });
            }
            onActionComplete?.();
            return;
        }
        if (dropActionType === 'REMINDER_SET') {
            removeRSVP().then(() => setUpdatedDropActionType('REMIND_ME'));
        }

        const resource = drop as TResource;

        if (dropActionType === 'LINK' && resourceType === 'article' && resource.content) {
            const articlePath = generatePath(
                getResourcePath({ ...resource, doc_type: 'article' }),
                { id: resource.slug ?? resource.id ?? '#' },
            );
            history.push(articlePath);
        } else if (dropActionType === 'LINK' && (resourceType === 'video' || resourceType === 'podcast' || resourceType === 'soundtrack') && resource.playbackId) {
            openVideoPlayer(resource, password);
            if (drop?.rewardSettings && !drop?.isReminded) {
                await RSVPDrops?.(resourceType, drop.id, userId ?? appUser?.id as string, password, false)

            }
            // onVideoUnlocked?.(password, drop.isDropUnlocked);
        } else if (dropActionType === 'LINK' && (resourceType === "album")) {
            if (drop?.rewardSettings && !drop?.isReminded) {
                await RSVPDrops?.(resourceType, drop.id, userId ?? appUser?.id as string, password, false)
            }
            // onVideoUnlocked?.(password, drop.isDropUnlocked);
        }
    };
    const handleDropClick = async () => {
        if (DropUtils.isEventMoment(resourceType ?? drop.doc_type ?? 'collect')) handleEventClick();
        else handleResourceClick();
    };

    const className = clsx(classes.actionBtn, { [classes.primaryActionBtn]: type === 'primary', [classes.secondaryActionBtn]: type === 'secondary' });

    const resourceLink = useMemo(() => {
        const resource = drop as TResource
        if (dropActionType === 'LINK' && resource.link && !resource.playbackId && !resource.content) return resource.link;
    }, [drop, dropActionType])

    // useEffect(() => {
    //   if (startOnLoad) handleDropClick();
    // }, []);

    const icons = (
        <>
            {drop.gateType === EGateType.PASSWORD_PROTECTED ? <SVGCIcon icon='icon-key' /> : null}
            {drop.gateType === EGateType.NFT_GATED ? <SVGCIcon icon="icon-cube" /> : null}
        </>
    )

    return (
        <>
            {resourceType === 'Event' ? (
                <PgButton
                    className={className}
                    secondary={type === 'secondary'}
                    primary={type === 'primary'}
                    fullWidth
                    onClick={handleDropClick}
                    disabled={dropActionType === 'PAST_EVENT' || dropActionType === 'CANCELLED_EVENT' || dropActionType === 'SOLD_OUT' || isDisabled}
                    id={DROP_CARD_BTN_ID}
                >
                    <PgTypo c2>{buttonText}</PgTypo>
                    {icons}
                </PgButton>
            ) : (
                <PgButton
                    className={className}
                    secondary={type === 'secondary'}
                    primary={type === 'primary'}
                    fullWidth
                    onClick={handleDropClick}
                    href={resourceLink}
                    isExternalLink={dropActionType === 'LINK' && !!resourceLink}
                    disabled={isDisabled}
                    id={DROP_CARD_BTN_ID}
                >
                    <PgTypo c2>{buttonText}</PgTypo>
                    {icons}
                </PgButton>
            )}

            <Portal>
                {TicketingDialog}
                {PasswordDialog}
                {VideoPlayer}
                {RSVPComponent}
            </Portal>
        </>
    );
};

export default DropActionButton;

const useStyles = makeStyles<Theme, { type?: DropActionButtonType }>((theme) => {
    return createStyles({
        link: {
            flex: 1,
        },
        remindMeButton: {
            border: `1px solid ${theme.palette.common.black}`,
            // width: 326,
            width: '98%',
            '&>span': {
                // fontWeight: 700,
            },
            fontWeight: 700,
            fontSize: 14,
            color: theme.palette.common.black,
        },
        focus: {
            [theme.breakpoints.down('sm')]: {
                '&:hover': {
                    color: 'white',
                },
            },
        },
        dialogHeader: {
            padding: 4,
        },
        primaryActionBtn: {
            '&> span > div > svg, & > span > svg': {
                width: 14,
                marginLeft: 12,
                '&>path': {
                    fill: theme.palette.common.black,
                },
            },
        },
        secondaryActionBtn: {
            '&> span > div > svg, & > span > svg': {
                width: 14,
                marginLeft: 12,
                '& > path': { fill: theme.palette.common.black },
            },
            '&:hover': { '& > span > div > svg, & > span > svg': { '& > path': { fill: theme.palette.common.white } } },
        },
        actionBtn: {
            // '&:focus': {
            //   backgroundColor: 'transparent',
            // },
        },
    });
});
