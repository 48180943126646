import useShowToast from 'Hooks/useShowToast';
import { useStoreActions } from 'Stores';
import { IRenderActionButtons } from './ChatInput';
import { TMessage } from 'Models/Discussion/@types';
import { Picture } from 'Models/Picture/@types';

const useMessageActions = () => {
    const { editMessage } = useStoreActions(({ DiscussionStore: { editMessage } }) => ({
        editMessage,
    }));
    const showToast = useShowToast();

    const onMessageEdit = async ({ editedMessageText, images, message }: IRenderActionButtons & { message: TMessage }) => {
        if (!message) return;
        try {
            await editMessage({ discussionId: message.discussionId, message: { ...message, text: editedMessageText, images: images as Picture[] } });
            showToast('Message edit successful!', undefined, 'success');
        } catch (error) {
            showToast('Try again!', undefined, 'error');
        }
    };

    return {
        onMessageEdit,
    };
};

export default useMessageActions;
