/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-unused-expressions */
import useAsyncTask from 'Hooks/useAsyncTask';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import DiscussionModel from 'Models/Discussion';
import { getUserName } from 'Models/User/utils';
import { User } from 'Models/User/@types';
import { useStoreActions, useStoreDispatch, useStoreState } from 'Stores';
import { TDiscussionConfig } from './useDiscussion';
import { ANALYTICS_USER_EVENT } from 'Analytics/analyticActions';

export type DiscussionUserChangeAction = (action: 'remove' | 'add' | 'block' | 'unblock', user: User) => void;

const useUserActions = (config: TDiscussionConfig, onChange?: DiscussionUserChangeAction) => {
    const { appUser, discussions, community } = useStoreState(
        ({ AuthStore: { appUser }, DiscussionStore: { discussions }, CommunityStore: { community } }) => ({ appUser, discussions, community }),
    );

    const discussion = discussions.find((i) => i.id === config.activeDiscussionId);
    const { setCommunity, updateDiscussions } = useStoreActions(({ CommunityStore: { setCommunity }, DiscussionStore: { updateDiscussions } }) => ({
        setCommunity,
        updateDiscussions,
    }));
    const withConfirm = useConfirmationDialog();

    const dispatch = useStoreDispatch();

    const blockUser = useAsyncTask(async (user: User) => {
        if (!community || !appUser) return;
        withConfirm(
            async () => {
                await DiscussionModel.banUser(config.subject, config.subjectId, config.activeDiscussionId, user.id);
                setCommunity({
                    ...community,
                    bannedUserIds: [...(community.bannedUserIds || []), user.id],
                    discussions: (community.discussions || []).map((i) =>
                        i.id === discussion?.id ? { ...i, memberIds: i.memberIds.filter((i) => i !== user.id) } : i,
                    ),
                    onlineUserIds: community.onlineUserIds.filter((o) => o !== user.id),
                    onlineUsers: community.onlineUsers.filter((o) => o.id !== user.id),
                    offlineUsers: community.offlineUsers.filter((o) => o.id !== user.id),
                });
                dispatch({
                    type: ANALYTICS_USER_EVENT,
                    data: { eventName: 'COMMUNITY_BLOCK', context: { user, community, communityId: community.id }, source: window.location.pathname },
                });
                if (discussion)
                    updateDiscussions({
                        discussion: { ...discussion, memberIds: discussion.memberIds.filter((i) => i !== user.id) },
                        action: 'UPDATE',
                    });
                onChange?.('block', user);
            },
            {
                message: 'Block from community',
                body: `${getUserName(
                    user,
                )} will be removed from your community, and will no longer be able to participate in any of your conversations.`,
                agreeText: 'BLOCK',
            },
            {
                successToastMessage: `${getUserName(user)} has been blocked from your community`,
            },
        );
    });
    const unBlockUser = useAsyncTask(async (user: User) => {
        if (!community || !appUser) return;
        withConfirm(
            async () => {
                await DiscussionModel.banUser(config.subject, config.subjectId, config.activeDiscussionId, user.id, false);
                setCommunity({
                    ...community,
                    bannedUserIds: community.bannedUserIds?.filter((i) => i !== user.id),
                    discussions: (community.discussions || []).map((i) =>
                        i.id === discussion?.id ? { ...i, memberIds: i.memberIds.filter((i) => i !== user.id) } : i,
                    ),
                });
                onChange?.('unblock', user);
            },
            {
                message: 'Unblock from community',
                body: `Are sure you want to unblock @${getUserName(user)}, once unblocked they will be able to see all conversations`,
                agreeText: 'UNBLOCK',
            },
            {
                successToastMessage: `${getUserName(user)} has been un-blocked from your community`,
            },
        );
    });
    const joinUser = useAsyncTask(async (user: User) => {
        if (!community || !appUser || !discussion) return;

        withConfirm(
            async () => {
                await DiscussionModel.leaveJoinDiscussion(discussion.id, user.id, 'join');
                setCommunity({
                    ...community,
                    discussions: (community.discussions || []).map((i) =>
                        i.id === discussion.id ? { ...i, memberIds: [...i.memberIds, user.id] } : i,
                    ),
                });
                if (discussion) updateDiscussions({ discussion: { ...discussion, memberIds: [...discussion.memberIds, user.id] }, action: 'UPDATE' });
                onChange?.('add', user);
            },
            {
                message: 'Add to conversation',
                body: `${getUserName(user)} will be added to this conversation.`,
                agreeText: 'ADD',
            },
            {
                successToastMessage: `${getUserName(user)} has been added to #${discussion.title}`,
            },
        );
    });
    const removeUser = useAsyncTask(async (user: User) => {
        if (!community || !appUser || !discussion) return;

        withConfirm(
            async () => {
                await DiscussionModel.leaveJoinDiscussion(discussion.id, user.id, 'leave');
                setCommunity({
                    ...community,
                    discussions: (community.discussions || []).map((i) =>
                        i.id === discussion.id ? { ...i, memberIds: i.memberIds.filter((i) => i !== user.id) } : i,
                    ),
                });
                if (discussion)
                    updateDiscussions({
                        discussion: { ...discussion, memberIds: discussion.memberIds.filter((i) => i !== user.id) },
                        action: 'UPDATE',
                    });
                onChange?.('remove', user);
            },
            {
                message: 'Remove from conversation',
                body: `${getUserName(
                    user,
                )} will be removed from this conversation, but can still participate in other conversations within your community.`,
                agreeText: 'REMOVE',
            },
            {
                successToastMessage: `${getUserName(user)} has been removed from #${discussion.title}`,
            },
        );
    });

    return {
        removeUser,
        blockUser,
        unBlockUser,
        joinUser,
    };
};
export default useUserActions;
