import Menu, { MenuOption } from 'Components/Menu';
import PgButton, { PgButtonProps } from 'Components/PgButton';
import React, { FC, Ref, useMemo, useState } from 'react';
import { TDropType } from 'Models/Drop/@types';
import PgIcon from 'Components/PgIcon';
import { Box, ButtonBase, Icon, Theme, ThemeProvider, Tooltip, createStyles } from '@material-ui/core';
import LightTheme from 'Theme';

import EVENT_ICON from 'Assets/icons/add-drop.svg';
import VIDEO_ICON from 'Assets/icons/video.svg';
import ARTICLE_ICON from 'Assets/icons/article.svg';
import MERCH_ICON from 'Assets/icons/merch.svg';
import { makeStyles } from '@material-ui/styles';
import useLayoutQuery from 'Hooks/useLayoutQuery';
import { BORDER_BLACK, THEME_PALETTE } from 'Theme/themeConstants';
import { generatePath, useHistory } from 'react-router-dom';
import { EVENT_MEDIA_ROUTES } from 'Routes/DashboardRoutes/EventsAndMediaNavigation';
import { useStoreState } from 'Stores';
import { Add } from '@material-ui/icons';
import clsx from 'clsx';
import PgTypo from 'Components/PgTypo';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';
import { TUserCommunity } from 'Models/User';

const withToolTip = (child: JSX.Element, label?: string) => label ? (<Tooltip title={label} placement='left' arrow>{child}</Tooltip>) : child

type OptionClickFn = (dropType: TDropType) => void;

const OptionTab: FC<{ label: string, icon: JSX.Element, onClick: () => void, sidebarOpen?: boolean }> = (props) => {
  const { label, icon, onClick, sidebarOpen } = props;
  return (
    <ButtonBase onClick={onClick} style={{ width: '100%' }}>
      {withToolTip(
        <Box width='100%' display='flex' alignItems='center' justifyContent='center' px={2} py={1} borderBottom={BORDER_BLACK}>
          <Box minWidth={sidebarOpen ? 42 : 'auto'} display='inline-flex'>{icon}</Box>
          {sidebarOpen && <PgTypo b2 align='left' style={{ flex: 1, letterSpacing: '1px' }}>{label}</PgTypo>}
        </Box>,
        sidebarOpen ? undefined : label,
      )}
    </ButtonBase>
  )
}

const Options = (onOptionClick?: OptionClickFn, sidebarOpen = true) => [
  { label: 'Event', icon: <img src={EVENT_ICON} alt='event-icon' height={24} />, onClick: () => onOptionClick?.('event') },
  { label: 'Video', icon: <img src={VIDEO_ICON} alt='video-icon' height={24} />, onClick: () => onOptionClick?.('video') },
  { label: 'Album', icon: <SVGCIcon icon='icon-album' />, onClick: () => onOptionClick?.('album') },
  { label: 'Soundtrack', icon: <SVGCIcon icon='icon-soundtrack' />, onClick: () => onOptionClick?.('soundtrack') },
  { label: 'Podcast', icon: <PgIcon icon='icon-podcast' />, onClick: () => onOptionClick?.('podcast') },
  { label: 'Article', icon: <img src={ARTICLE_ICON} alt='article-icon' height={24} />, onClick: () => onOptionClick?.('article') },
  { label: 'Merch', icon: <img src={MERCH_ICON} alt='merch-icon' height={24} />, onClick: () => onOptionClick?.('collect') },
].map(m => <OptionTab {...m} sidebarOpen={sidebarOpen} key={m.label} />) as MenuOption[]

export interface AddDropButtonProps extends PgButtonProps {
  btnname?: string
  userCommunity?: TUserCommunity;
}
const AddDropButton: FC<AddDropButtonProps> = (props) => {

  const { btnname, userCommunity, disabled } = props;

  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const { community, open } = useStoreState(({ CommunityStore: { community }, DrawerSidebarStore: { open } }) => ({ community, open }));

  const { isDeviceSm } = useMediaQuery();

  const { communityId, isDisabled } = useMemo(() => {
    const _community = userCommunity ?? community;
    const communityId = _community?.slug || _community?.id || '#'
    const isDisabled = _community?.isDeactivated || _community?.isDeletionRequested || props.disabled;
    return { _community, communityId, isDisabled }
  }, [userCommunity, community, disabled])

  const sidebarOpen = useMemo(() => open || isDeviceSm, [open, isDeviceSm]);

  const openMenu = (e: React.MouseEvent<HTMLButtonElement>) => setAnchor(e.currentTarget);

  const { width, ref } = useLayoutQuery();

  const classes = useStyles({ width });

  const history = useHistory();

  const handleOptionClick: OptionClickFn = (dropType) =>
    history.push(generatePath(EVENT_MEDIA_ROUTES.NEW_DROP_FORM.path, { communityId: communityId, dropType }))



  return (
    <ThemeProvider theme={LightTheme}>
      <PgButton
        ref={ref as unknown as Ref<HTMLButtonElement>}
        primary
        fullWidth
        onClick={openMenu}
        className={clsx({ [classes.btn]: !sidebarOpen })}
        disabled={isDisabled}
        {...props}
      >
        {sidebarOpen || btnname ? btnname ?? 'add drop' : <Add htmlColor={THEME_PALETTE.common.black} style={{ fontSize: 25 }} />}
      </PgButton>
      <Menu
        id="add-drop-menu"
        handleClose={() => setAnchor(null)}
        anchorEl={anchor}
        options={Options(handleOptionClick, sidebarOpen)}
        borderColor={THEME_PALETTE.common.black}
        menuProps={{ className: classes.menu }}
      />
    </ThemeProvider>
  )
}

export default AddDropButton;

const useStyles = makeStyles<Theme, { width: number }>((theme: Theme) => createStyles({
  menu: props => ({ '& ul': { width: props.width } }),
  btn: { minWidth: 40, width: 40, height: 40 },
}));
