import { Box, CircularProgress, Theme, createStyles, makeStyles } from '@material-ui/core'
import PgButton from 'Components/PgButton'
import React, { FC } from 'react'

export interface EditMessageActionButtonsProps {
    onEditCancel?: () => void;
    onEditDone?: () => Promise<void>;
    isLoading?: boolean;
    cancelText?: string;
    agreeText?: string
}

const EditMessageActionButtons: FC<EditMessageActionButtonsProps> = ({ onEditCancel, onEditDone, isLoading, cancelText, agreeText }) => {
    const classes = useStyles();
    return (
        <Box display={"flex"} flexDirection={"row"} gridGap={4} className={classes.sendButton}>
            <PgButton secondary onClick={onEditCancel}>
                {cancelText ?? "CANCEL"}
            </PgButton>
            <PgButton primary onClick={onEditDone}>
                {isLoading ? <CircularProgress size={24} /> : agreeText ?? "SAVE"}
            </PgButton>
        </Box>
    )
}

export default EditMessageActionButtons;
const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        sendButtons: {
            position: 'absolute',
            right: theme.spacing(0),
            bottom: "10px",
            marginLeft: "12px",
            padding: theme.spacing(1, 1.6),
        },
    });
});