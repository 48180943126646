import { useStoreState } from 'Stores';
import helpers from 'Utils/helpers';
import React, { FC, useMemo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router';
import { DropFormState } from '../DropFormScreenViewModel';
import { Box, ButtonBase, Theme, alpha, createStyles, makeStyles } from '@material-ui/core';
import PgTypo from 'Components/PgTypo';
import PgButton from 'Components/PgButton';
import ToRight from 'Assets/icons/sidebar/to_right.svg';
import { BORDER_WHITE } from 'Theme/themeConstants';
import { capitalize } from 'lodash';
import PgIcon from 'Components/PgIcon';
import clsx from 'clsx';
import { TDropType } from 'Models/Drop/@types';
import useShowToast from 'Hooks/useShowToast';

export interface DropFormSidebarProps {
  onChange?: (open: boolean) => void;
}

const DropFormSidebar: FC<DropFormSidebarProps> = (props) => {

  const { onChange } = props;

  const { open } = useStoreState(({ DrawerSidebarStore: { open } }) => ({ open }));
  const showToast = useShowToast();
  const { search, state, pathname } = useLocation();
  const history = useHistory();
  const formState = useMemo<DropFormState>(() => helpers.locationParams<{ formState: DropFormState }>(search)?.formState ?? 'details', [search]);

  const classes = useStyles({ open });

  const dropType = useMemo(() => (state as any)['dropType'] as TDropType, [state]);
  const isEditFlow = useMemo(() => Boolean(pathname.includes('/edit')), [pathname]);

  const handleTabClick = (tab: DropFormState) => {
    if ((tab === 'settings' || tab === 'preview') && !isEditFlow) {
      showToast(`Please complete details and save the ${dropType} before accessing ${tab}`, undefined, "error");
    } else {
      history.replace({ search: `formState=${tab}` }, state);
    }
  }


  return (
    <div>
      <Box
        height={open ? 61 : 'auto'}
        display='flex'
        flexDirection='column'
        justifyContent='center'
        pt={0.5}
        pb={open ? 2 : 0}
        borderBottom={BORDER_WHITE}
      >
        {!open && (
          <Box component={ButtonBase} onClick={() => onChange?.(true)} pb={2}>
            <img src={ToRight} alt="collapsed-icon" className={classes.toRight} />
          </Box>
        )}
        <PgButton fullWidth onClick={history.goBack} className={classes.tab}>
          <PgIcon icon='icon-arrow-back' />
          {open && <PgTypo h6 style={{ textTransform: 'none' }}>Your {capitalize(dropType === 'collect' ? 'merch' : dropType)}</PgTypo>}
        </PgButton>
      </Box>
      <Box>
        {DROP_FORM_TABS(isEditFlow).map(tab => (
          <PgButton
            fullWidth
            className={clsx(classes.tab, { [classes.activeTab]: tab.id === formState })}
            key={tab.id}
            onClick={() => handleTabClick(tab.id)}
          // disabled={tab.isDisabled}
          >
            {tab.icon}
            {open && <PgTypo variant='caption' style={{ textTransform: 'none' }}>{tab.name}</PgTypo>}
          </PgButton>
        ))}
      </Box>
    </div>
  );
}

export default DropFormSidebar;

const useStyles = makeStyles<Theme, { open: boolean }>((theme) => createStyles({
  tab: {
    padding: theme.spacing(2),
    justifyContent: ({ open }) => open ? 'flex-start' : 'center',
    '& > span': { gap: theme.spacing(1) },
    '&:focus': { backgroundColor: alpha(theme.palette.common.black, 0.04) },
  },
  activeTab: { backgroundColor: alpha(theme.palette.common.black, 0.04), },
  toRight: { filter: theme.palette.type === 'dark' ? 'invert(100%)' : undefined },
}));

export const DROP_FORM_TABS = (isEditState: boolean): Array<{ id: DropFormState, name: string, icon: JSX.Element, isDisabled?: boolean }> => [
  { id: 'details', name: 'Details', icon: <PgIcon icon='icon-edit' /> },
  { id: 'settings', name: 'Settings', icon: <PgIcon icon='icon-settings' /> },
  { id: 'preview', name: 'Preview', icon: <PgIcon icon='icon-view-on' />, isDisabled: !isEditState },
];
