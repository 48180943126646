import PgButton, { PgButtonProps } from 'Components/PgButton'
import useAuthDialog from 'Features/Auth/useAuthDialog';
import { APP_ROUTES } from 'Routes';
import { useStoreState } from 'Stores';
import React, { FC } from 'react'
import { useHistory } from 'react-router';

interface CreateCommunityButtonProps extends PgButtonProps {

}
const CreateCommunityButton: FC<CreateCommunityButtonProps> = (props) => {
    const { className, children } = props;
    const { openDialog } = useAuthDialog();
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
    const history = useHistory();

    const onCreateNewCommunity = () => {
        if (!appUser?.emailVerified) {
            openDialog('verify-email-first');
            return;
        }
        history.push(APP_ROUTES.CREATE_COMMUNITY.path);
    };

    return (
        <PgButton primary onClick={onCreateNewCommunity} className={className}>
            {children}
        </PgButton>
    )
}

export default CreateCommunityButton