/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useState } from 'react';
import useToastMessage from 'Hooks/useToastMessage';
import UserModel, { TUserFavorited, TUserFollows, User } from 'Models/User';
import MESSAGES, { getTemplateString } from 'Utils/Messages';
import DropModel from 'Models/Drop';
import { TContentType } from 'Models/Drop/@types';
import { useStoreDispatch, useStoreState } from 'Stores';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import CommunityModel from 'Models/Community';

import { Step } from '../Auth/@types';
import { get, isEmpty } from 'lodash';
import { useLocation } from 'react-router';
import { TCommunity } from 'Models/Community/@types';
import { FollowChangeHandler } from 'Features/Profile/@types';
import { ANALYTICS_SET_GROUP, ANALYTICS_USER_EVENT } from 'Analytics/analyticActions';
import { useMediaQuery } from 'Hooks/useMediaQuery';

export type UserActionRequirementType = 'LOGIN' | 'VERIFY_EMAIL' | 'VERIFY_PHONE';
const DEFAULT_OPTIONAL_ACTION: UserActionRequirementType[] = ['VERIFY_PHONE'];
export interface IUserActions {
    addFavourite: (docType: TContentType, subjectId: string, userId: string) => Promise<TUserFavorited<User> | undefined>;
    removeFavourite: (favouriteId: string) => Promise<void>;
    RSVP: (
        docType: TContentType,
        subjectId: string,
        userId: string,
        password?: string,
        showToast?: boolean,
    ) => Promise<TUserFollows<User> | undefined>;
    optOut: (rsvpId: string) => Promise<void>;
    joinCommunity: (communityId: string, userId: string, name: string) => Promise<TUserFollows<User> | undefined>;
    leaveCommunity: (followId: string, name: string) => Promise<void>;
    updateUser: (id: string, data: Partial<User>, toastMessage?: string | undefined) => Promise<User>;
    isUserEligible: (optionalRequirements?: UserActionRequirementType[]) => Promise<boolean>;
    openDialog: (page: Step, meta?: any) => Promise<Step | undefined>;
    doAction: (method: Function, args: any, optionalRequirements?: UserActionRequirementType[]) => Promise<any>;
    joinCommunityProfile: (
        profile: TCommunity,
        appUser: User,
        setAppUser: (user: User) => void,
        onFollowChange?: FollowChangeHandler,
    ) => Promise<void>;
}

const useUserActions = (onAuthDialogCompletion?: (step?: Step) => void): IUserActions => {
    const withToast = useToastMessage();
    const { openDialog } = useAuthDialog(onAuthDialogCompletion);
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({
        appUser,
    }));

    const { isDeviceSm } = useMediaQuery();

    // const getUserActionRequirement = useCallback((): UserActionRequirementType | undefined => {
    const getUserActionRequirement = (): UserActionRequirementType | undefined => {
        if (!appUser) return 'LOGIN';
        if (!appUser?.emailVerified) return 'VERIFY_EMAIL';
        // if (!appUser?.phoneVerified && !appUser?.dontAskPhoneRsvp) return 'VERIFY_PHONE';
    };
    // }, [appUser]);

    /**
     * //TODO rename method to something suitable
     */
    // const isUserEligible = useCallback(
    const isUserEligible = async (optionalRequirements?: UserActionRequirementType[]) => {
        const requirement = getUserActionRequirement();
        if (requirement === 'LOGIN') {
            await openDialog();
            return false;
        }

        if (requirement === 'VERIFY_EMAIL' && !optionalRequirements?.includes('VERIFY_EMAIL')) {
            await openDialog('verifyEmailPrompt');
            return false;
        }

        if (requirement === 'VERIFY_PHONE' && !optionalRequirements?.includes('VERIFY_PHONE')) {
            try {
                await openDialog('verifyPhone');
            } catch (err) {
                //Let the user continue even if there is an error
            }

            return false;
        }
        return true;
    };
    //   [appUser]
    // );

    const doAction = async (method: Function, rest: any, optionalRequirements?: UserActionRequirementType[]) => {
        const res = await isUserEligible(optionalRequirements);
        if (!res) return;
        return (method as Function)(...rest);
    };

    const addFavourite = async (docType: TContentType, subjectId: string, userId: string): Promise<TUserFavorited<User> | undefined> => {
        const res = await withToast<TUserFavorited<User>>(async () => DropModel.addFavourite(docType, subjectId, userId ?? appUser?.id), {
            successToastMessage: MESSAGES.FAVOURITE_ADDED,
            showApiErrorMsg: true,
        });
        return res;
    };

    const removeFavourite = async (favouriteId: string) => {
        await withToast(async () => DropModel.removeFavourite(favouriteId), {
            successToastMessage: MESSAGES.FAVOURITE_REMOVED,
            showApiErrorMsg: true,
        });
    };

    const RSVP = async (
        docType: TContentType,
        subjectId: string,
        userId: string,
        password?: string,
        showToast: boolean = true,
    ): Promise<TUserFollows<User> | undefined> => {
        const res = await (showToast
            ? withToast<TUserFollows<User>>(
                  async () => {
                      return DropModel.RSVP(docType, subjectId, userId ?? appUser?.id, password);
                  },
                  {
                      successToastMessage: MESSAGES.RSVP_JOINED,
                      showApiErrorMsg: false,
                      errorToastMessage: (err) => (get(err, 'response.data.error.statusCode') === 401 ? MESSAGES.DROP_PASSWORD_INVALID : ''),
                  },
              )
            : DropModel.RSVP(docType, subjectId, userId ?? appUser?.id, password));
        return res;
    };

    const optOut = async (rsvpId: string) => {
        await withToast(async () => DropModel.optOut(rsvpId), { successToastMessage: MESSAGES.RSVP_REMOVED, showApiErrorMsg: true });
    };

    const joinCommunity = async (communityId: string, userId: string, name: string): Promise<TUserFollows<User> | undefined> => {
        const res = await withToast<TUserFollows<User>>(async () => CommunityModel.joinCommunity(communityId, userId ?? appUser?.id), {
            successToastMessage: getTemplateString('COMMUNITY_JOINED', { community: name }),
            errorToastMessage: getTemplateString('COMMUNITY_JOIN_FAILED', { community: name }),
        });
        return res;
    };

    const leaveCommunity = async (followId: string, name: string) => {
        await withToast<TUserFollows<User>>(async () => CommunityModel.leaveCommunity(followId), {
            genraToastMessage: getTemplateString('COMMUNITY_LEFT', { community: name }),
        });
    };

    const updateUser = async (id: string, data: Partial<User>, toastMessage?: string) => {
        const res = await withToast<User>(async () => UserModel.updateUser({ id, ...data }), {
            successToastMessage: toastMessage ?? MESSAGES.PROFILE_UPDATE_SUCCESS,
        });
        return res;
    };

    const dispatch = useStoreDispatch();
    const { pathname } = useLocation();
    const joinCommunityProfile = async (
        profile: TCommunity,
        appUser: User,
        setAppUser: (user: User) => void,
        onFollowChange?: FollowChangeHandler,
    ) => {
        try {
            const res: TUserFollows<User> | undefined = await joinCommunity(profile.id, appUser?.id as string, profile.name as string);
            // const res: TUserFollows<User> | undefined = await doAction(joinCommunity, [profile.id, appUser?.id as string, profile.name as string]);
            if (res) {
                CommunityModel.getCommunityById(profile?.id, { include: ['discussions'] })
                    .then(async (comm) => {
                        setAppUser({
                            ...appUser,
                            followsCommunities: [
                                ...(appUser.followsCommunities ?? []),
                                { ...profile, discussionId: comm.discussionId, discussions: comm.discussions },
                            ],
                        } as User);
                        onFollowChange?.({ ...profile, ...comm, followersCount: (profile.followersCount || 0) + 1 }, true, res.id);
                        dispatch({
                            type: ANALYTICS_USER_EVENT,
                            data: {
                                eventName: 'JOIN_COMMUNITY',
                                context: { community: profile, user: appUser, source: pathname, communityId: profile.id },
                            },
                        });
                        const result = await CommunityModel.relatedEntitySearch(profile?.id ?? '', '', { limit: 1 });
                        dispatch({
                            type: ANALYTICS_SET_GROUP,
                            data: {
                                groupId: profile?.id,
                                traits: {
                                    subscribers: result?.aggregations?.subscribers.doc_count,
                                    attendees: result?.aggregations?.attendees.doc_count,
                                    invited: result?.aggregations?.invitees.doc_count,
                                    inviteExpired: result?.aggregations?.expiredInvites.doc_count,
                                    blocked: result?.aggregations?.banned.doc_count,
                                },
                            },
                        });
                    })
                    .catch((err) => {
                        setAppUser({ ...appUser, followsCommunities: [...(appUser.followsCommunities ?? []), profile] } as User);
                        onFollowChange?.(profile, true, res.id);
                    });
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    return {
        addFavourite,
        removeFavourite,
        RSVP,
        optOut,
        joinCommunity,
        leaveCommunity,
        updateUser,
        isUserEligible,
        openDialog,
        doAction,
        joinCommunityProfile,
    };
};

export default useUserActions;
