import { Box, BoxProps, CircularProgress, Divider, LinearProgress } from '@material-ui/core';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PgTypo from 'Components/PgTypo';
import Typo from 'Components/Typo';
import { UNVERIFIED_EMAIL_BANNER_ID } from 'Constants/variables';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { TDiscussion, TMessage } from 'Models/Discussion/@types';
import React, { FC, memo, useContext, useEffect, useMemo } from 'react';
import { SHADES } from 'Theme/newThemeConstants';
import InfiniteScrollList from '../../Components/InfiniteScrollList';
import { MEMBER_DRAWER_WIDTH } from './ActiveMemberDrawer';
import ChatInput from './ChatInput';
import DiscussionGates from './DiscussionGates';
import _MessageCard from './MessageCard';
import { TDiscussionConfig } from './useDiscussion';
import { APP_HEADER_HEIGHT } from 'Features/AppHeader';
import { useStoreState } from 'Stores';
import LiveStream from './LiveStream';
import { ChatContext } from 'Contexts/ChatContext';
import VideoChat from 'Features/VideoChat';
import { history } from 'App';
import { BORDER_BLACK } from 'Theme/themeConstants';

const MessageCard = memo(_MessageCard);

export interface DiscussionLayoutProps {
    config: TDiscussionConfig;
    allowMessaging?: boolean;
    memberDrawerDisabled?: boolean;
    containerClass?: string;
    getDiscussionHeader?: (config: TDiscussionConfig, discussion: TDiscussion, isAuthenticated: boolean) => React.ReactNode;
    getDiscussionSideComp?: (config: TDiscussionConfig, discussion: TDiscussion) => React.ReactNode;
}

const DiscussionLayout: FC<DiscussionLayoutProps> = ({
    config,
    allowMessaging = true,
    getDiscussionHeader,
    memberDrawerDisabled = false,
    containerClass,
    getDiscussionSideComp,
}) => {
    const { disableChatConfig } = config;
    const unverifiedBanner = useMemo(() => document.getElementById(UNVERIFIED_EMAIL_BANNER_ID), [document.getElementById]);
    const classes = useStyles({ hasMargin: !memberDrawerDisabled, unverifiedBannerHeight: unverifiedBanner?.clientHeight ?? 0 });
    // const chatHookProps = useDiscussion({ ...config, reverse: true });
    const chatHookProps = useContext(ChatContext)
    const {
        sendMessage,
        loading,
        currentDiscussion,
        isAdmin,
        isBanned,
        hasGate,
        handleUnlockedDiscussion,
        fetchMessages,
        handlePinUnpin,
        fetchReplies,
        hasLeftChat,
        isFetchingMessage,
    } = chatHookProps;
    const { isDeviceSm } = useMediaQuery();

    const isDisabled = useMemo(() => !!currentDiscussion?.isDisabled, [currentDiscussion?.isDisabled]);
    const isUserReadOnly = useMemo(
        () => !!currentDiscussion?.permissions.isUserReadOnly && !isAdmin,
        [currentDiscussion?.permissions.isUserReadOnly, isAdmin],
    );

    useEffect(() => {
        // if (unverifiedBanner && isDeviceSm) {
        //     unverifiedBanner.style.position = 'fixed';
        //     unverifiedBanner.style.left = '0';
        //     unverifiedBanner.style.right = '0';
        // }

        const { pathname, search } = window.location;
        const unblock = history.block((location, action) => {
            if (unverifiedBanner && (location.pathname !== pathname || location.search !== search))
                unverifiedBanner.style.position = 'relative';
        });

        return () => {
            unblock();
        };
    }, [unverifiedBanner, isDeviceSm]);

    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }))

    const mainComponent = useMemo<JSX.Element | null>(() => {
        if (!currentDiscussion) return null;
        if (disableChatConfig?.isDisabled) return (
            <Box py={9} display="flex" alignItems="center" justifyContent="center">
                <PgTypo h6 className={classes.accessRestrictedMessage} align="center">
                    {disableChatConfig.disabledMessage}
                </PgTypo>
            </Box>
        )
        if (hasGate && !isAdmin) return <DiscussionGates onUnlock={handleUnlockedDiscussion} discussion={currentDiscussion} />
        if ((isDisabled || isBanned) && !isAdmin) return (
            <PgTypo variant="h6" align="center" className={classes.text}>
                This conversation is inactive.
            </PgTypo>
        )
        if (currentDiscussion.discussionType === 'live-stream' && currentDiscussion.stream) return <LiveStream {...currentDiscussion} />
        if (currentDiscussion.discussionType === 'video') return <VideoChat {...currentDiscussion} />
        return <ChatLoader allowMessaging={allowMessaging} memberDrawerDisabled={memberDrawerDisabled} />
    }, [currentDiscussion?.id, currentDiscussion?.discussionType, hasGate, isAdmin, isDisabled, isBanned, disableChatConfig?.isDisabled])

    if (loading) return (
        <Box m={isDeviceSm ? 2 : 4} boxSizing="border-box">
            <LinearProgress />
        </Box>
    )
    if (!currentDiscussion) return null;

    return (
        <Box display='flex' alignItems='flex-start' height='100%' style={{ margin: '0px !important' }}>
            <Box position='relative' height='100%' width='100%' display='flex' flexDirection='column' className={classes.root}>
                {!!getDiscussionHeader && getDiscussionHeader?.(config, currentDiscussion, !hasGate)}
                {mainComponent}
            </Box>
            {!!getDiscussionSideComp && getDiscussionSideComp(config, currentDiscussion)}
        </Box>
    );

    // const marginY = useMemo(() => isDeviceSm ? `${8 + (unverifiedBanner?.clientHeight ?? 0)}px` : 1, [isDeviceSm, unverifiedBanner?.clientHeight])
    // if (loading)
    //     return (
    //         <Box mx={isDeviceSm ? 2 : 4} my={marginY} boxSizing="border-box">
    //             <LinearProgress />
    //         </Box>
    //     );
    // if (!currentDiscussion) return <Box mx={isDeviceSm ? 2 : 4} my={1} boxSizing="border-box" />;

    // return (
    //     <>
    //         <Box className={clsx(classes.root, containerClass)} id="discussion-box">
    //             {!!getDiscussionHeader && (
    //                 <Box className={classes.topChatHeader}>
    //                     {getDiscussionHeader?.(config, currentDiscussion, !hasGate)}
    //                     <Divider className={classes.divider} orientation="horizontal" />
    //                 </Box>
    //             )}
    //             {disableChatConfig?.isDisabled ? (
    //                 <Box py={9} display="flex" alignItems="center" justifyContent="center">
    //                     <PgTypo h6 className={classes.accessRestrictedMessage} align="center">
    //                         {disableChatConfig.disabledMessage}
    //                     </PgTypo>
    //                 </Box>
    //             ) : hasGate && !isAdmin ? (
    //                 <DiscussionGates onUnlock={handleUnlockedDiscussion} discussion={currentDiscussion} />
    //             ) : (isDisabled || isBanned) && !isAdmin ? (
    //                 <Box mx={0} my={4}>
    //                     <Typo variant="h6" weight="bold" align="center" className={classes.text}>
    //                         This conversation is inactive.
    //                     </Typo>
    //                 </Box>
    //             ) : (
    //                 <>
    //                     {currentDiscussion.discussionType === 'video' ? (
    //                         <VideoChat {...currentDiscussion} />
    //                     ) : currentDiscussion.discussionType === 'live-stream' && currentDiscussion.stream ? (
    //                         <LiveStream {...currentDiscussion} />
    //                     ) : (
    //                         <ChatLoader allowMessaging={allowMessaging} memberDrawerDisabled={memberDrawerDisabled} />
    //                     )}
    //                 </>
    //             )}
    //         </Box>
    //     </>
    // );
};

export const ChatList: FC<{ allowMessaging: boolean, rootProps?: BoxProps }> = ({ allowMessaging, rootProps }) => {
    const {
        isFetchingMessage,
        currentDiscussion,
        isAdmin,
        fetchMessages,
        fetchReplies,
        chatConfig: config,
        sendMessage,
        handlePinUnpin,
        hasLeftChat,
    } = useContext(ChatContext);
    const { messages, isDisabled } = useMemo(() => ({
        messages: currentDiscussion?.messages ?? [],
        isDisabled: !!currentDiscussion?.isDisabled,
    }), [currentDiscussion?.isDisabled, currentDiscussion?.messages]);
    const classes = useStyles({ hasMargin: false, unverifiedBannerHeight: 0 });
    if (!currentDiscussion || !config) return null;
    return (
        <>
            {isFetchingMessage && (
                <Box mx='auto' my={2}>
                    <CircularProgress />
                </Box>
            )}
            {messages?.length === 0 ? (
                <Typo variant="h6" weight="bold" align="center" className={classes.text}>
                    Be the first to comment!
                </Typo>
            ) : null}
            <Box
                flex={1}
                width='100%'
                id="scrollableDiv"
                display='flex'
                flexDirection={messages?.length ? 'column-reverse' : undefined}
                overflow='auto'
                {...rootProps}
            >
                <InfiniteScrollList
                    inverse
                    dataLength={messages?.length || 0} // This is important field to render the next data
                    next={async () => fetchMessages(false)}
                    hasMore={currentDiscussion?.hasMoreMessages || false}
                    loader={<div />}
                    style={{ height: '100%', overflow: 'visible', width: '100%' }}
                    scrollThreshold={0.5}
                    scrollableTarget="scrollableDiv"
                    className={clsx(classes.infiniteScroll, { [classes.disabled]: isDisabled })}
                >
                    <div className={classes.messageContainer} id="message-container">
                        {!!messages?.length && messages.map((msg) => {
                            if (!isAdmin && msg.isHidden && !msg.repliesCount) return null;
                            const transformedMsg: TMessage = msg.isDeleted
                                ? { ...msg, replies: [], repliesCount: 0 }
                                : { ...msg };
                            return (
                                <Box key={msg.id} id={msg.id} className={clsx(classes.msgItem)}>
                                    <MessageCard
                                        config={config}
                                        isDisabled={isDisabled}
                                        sendMessage={sendMessage}
                                        handlePinUnpin={handlePinUnpin}
                                        message={transformedMsg}
                                        isCreator={isAdmin}
                                        fetchReplies={fetchReplies}
                                        isMessageByCreator={msg.userId === config.creatorId}
                                        disableReply={!allowMessaging || hasLeftChat}
                                        chatPermissions={currentDiscussion?.permissions}
                                    />
                                </Box>
                            );
                        })}
                    </div>
                </InfiniteScrollList>
            </Box>
        </>
    )
}

const _ChatLoader: FC<{ allowMessaging: boolean, memberDrawerDisabled?: boolean, rootProps?: BoxProps }> = ({ allowMessaging, memberDrawerDisabled, rootProps }) => {
    const { isFetchingMessage, currentDiscussion, isAdmin, fetchMessages, sendMessage, handlePinUnpin, fetchReplies, chatConfig: config, hasLeftChat } = useContext(ChatContext);
    const messages = useMemo(() => currentDiscussion?.messages, [currentDiscussion?.messages]);
    const isDisabled = useMemo(() => !!currentDiscussion?.isDisabled, [currentDiscussion?.isDisabled]);
    const isUserReadOnly = useMemo(
        () => !!currentDiscussion?.permissions.isUserReadOnly && !isAdmin,
        [currentDiscussion?.permissions.isUserReadOnly, isAdmin],
    );
    const unverifiedBanner = useMemo(() => document.getElementById(UNVERIFIED_EMAIL_BANNER_ID), [document.getElementById]);
    const classes = useStyles({ hasMargin: !memberDrawerDisabled, unverifiedBannerHeight: unverifiedBanner?.clientHeight ?? 0 });
    const { isDeviceSm } = useMediaQuery();
    if (!currentDiscussion || !config) return null;
    return (
        <>
            {/* {isFetchingMessage && (
                <Box alignSelf={'center'}>
                    <CircularProgress />
                </Box>
            )}
            {messages?.length === 0 ? (
                <Box mx={0} my={4}>
                    <Typo variant="h6" weight="bold" align="center" className={classes.text}>
                        Be the first to comment!
                    </Typo>
                </Box>
            ) : null}
            <Box display={'flex'} id={'scrollableDiv'} className={classes.messageWrappers} flexDirection={'column-reverse'}>
                <InfiniteScrollList
                    inverse
                    dataLength={messages?.length || 0} // This is important field to render the next data
                    next={async () => fetchMessages(false)}
                    hasMore={currentDiscussion?.hasMoreMessages || false}
                    loader={<div />}
                    style={{
                        height: '100%',
                        overflow: 'visible',
                    }}
                    scrollThreshold={0.5}
                    scrollableTarget="scrollableDiv"
                    className={clsx(classes.infiniteScroll, { [classes.disabled]: isDisabled })}
                >
                    <div className={classes.messageContainer} id="message-container">
                        {!!messages?.length && messages.map((msg) => {
                            if (!isAdmin && msg.isHidden && !msg.repliesCount) return null;
                            return (
                                <Box key={msg.id} id={msg.id} className={clsx(classes.msgItem)}>
                                    <MessageCard
                                        config={config}
                                        isDisabled={isDisabled}
                                        sendMessage={sendMessage}
                                        handlePinUnpin={handlePinUnpin}
                                        message={msg}
                                        isCreator={isAdmin}
                                        fetchReplies={fetchReplies}
                                        isMessageByCreator={msg.userId === config.creatorId}
                                        disableReply={!allowMessaging || hasLeftChat}
                                        chatPermissions={currentDiscussion?.permissions}
                                    />
                                </Box>
                            );
                        })}
                    </div>
                </InfiniteScrollList>
            </Box>
            {allowMessaging && !isUserReadOnly && !hasLeftChat ? (
                <Box className={classes.bottomChatInput}>
                    <Divider className={classes.divider} orientation="horizontal" />
                    <ChatInput
                        sendMessage={sendMessage}
                        isDisabled={isDisabled}
                        rows={1}
                        className={clsx(classes.inputContainer, { [classes.disabled]: isDisabled })}
                        discussionId={config.activeDiscussionId}
                    />
                </Box>
            ) : null} */}

            <ChatList allowMessaging={allowMessaging} rootProps={{ px: 4, ...rootProps }} />

            {/* {isFetchingMessage && (
                <Box mx='auto' my={2}>
                    <CircularProgress />
                </Box>
            )}
            {messages?.length === 0 ? (
                <Typo variant="h6" weight="bold" align="center" className={classes.text}>
                    Be the first to comment!
                </Typo>
            ) : null}
            <Box
                flex={1}
                width='100%'
                id="scrollableDiv"
                display='flex'
                flexDirection={messages?.length ? 'column-reverse' : undefined}
                overflow='auto'
                px={isDeviceSm ? 2 : 4}
            >
                <InfiniteScrollList
                    inverse
                    dataLength={messages?.length || 0} // This is important field to render the next data
                    next={async () => fetchMessages(false)}
                    hasMore={currentDiscussion?.hasMoreMessages || false}
                    loader={<div />}
                    style={{ height: '100%', overflow: 'visible', width: '100%' }}
                    scrollThreshold={0.5}
                    scrollableTarget="scrollableDiv"
                    className={clsx(classes.infiniteScroll, { [classes.disabled]: isDisabled })}
                >
                    <div className={classes.messageContainer} id="message-container">
                        {!!messages?.length && messages.map((msg) => {
                            if (!isAdmin && msg.isHidden && !msg.repliesCount) return null;
                            return (
                                <Box key={msg.id} id={msg.id} className={clsx(classes.msgItem)}>
                                    <MessageCard
                                        config={config}
                                        isDisabled={isDisabled}
                                        sendMessage={sendMessage}
                                        handlePinUnpin={handlePinUnpin}
                                        message={msg}
                                        isCreator={isAdmin}
                                        fetchReplies={fetchReplies}
                                        isMessageByCreator={msg.userId === config.creatorId}
                                        disableReply={!allowMessaging || hasLeftChat}
                                        chatPermissions={currentDiscussion?.permissions}
                                    />
                                </Box>
                            );
                        })}
                    </div>
                </InfiniteScrollList>
            </Box> */}
            {allowMessaging && !isUserReadOnly && !hasLeftChat ? (
                <Box width='100%' position='sticky' bottom={0} px={isDeviceSm ? 2 : 4} py={1} borderTop={BORDER_BLACK} {...rootProps}>
                    <ChatInput
                        sendMessage={sendMessage}
                        isDisabled={isDisabled}
                        rows={1}
                        className={clsx({ [classes.disabled]: isDisabled })}
                        discussionId={config.activeDiscussionId}
                    />
                </Box>
            ) : null}
        </>
    )
}

export const ChatLoader = memo(_ChatLoader);

const useStyles = makeStyles<Theme, { hasMargin: boolean; unverifiedBannerHeight: number }>((theme) => {
    return createStyles({
        root: {
            // marginRight: (props) => (props.hasMargin ? MEMBER_DRAWER_WIDTH : 0),
            // height: (props) => `calc(100vh - ${82 + props.unverifiedBannerHeight}px)`,
            // display: 'flex',
            // flexDirection: 'column',
            // paddingBottom: theme.spacing(1),
            // [theme.breakpoints.down('sm')]: {
            //     height: (props) => `calc(100vh - ${66 + props.unverifiedBannerHeight}px)`,
            //     width: '100%',
            //     marginRight: 0,
            //     position: 'relative',
            //     paddingTop: props => props.unverifiedBannerHeight + 63,
            // },
            '& .infinite-scroll-component__outerdiv': { width: '100%' },
        },
        accessRestrictedMessage: {
            color: SHADES.grey,
            maxWidth: 520,
        },
        divider: {
            margin: theme.spacing(1, -4, 0),
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(1, -2),
            },
        },
        text: {
            color: `${theme.palette.text.disabled} !important`,
            width: 'auto',
            margin: theme.spacing(4, 2),
            flex: 1,
        },
        pinnedBackground: {
            marginBottom: theme.spacing(1),
            background: `${lighten(theme.palette.secondary.main, 0.95)} !important`,
        },
        inputContainer: {
            // height: 41,
            marginTop: 8,
        },
        messageWrappers: {
            // height: `calc(100vh - 281px)`,
            flex: 1,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse',
            [theme.breakpoints.down('sm')]: {
                // height: `calc(100vh - 258px)`,
                padding: theme.spacing(6, 2, 0),
                position: 'fixed',
                top: props => APP_HEADER_HEIGHT + props.unverifiedBannerHeight,
                left: 0,
                right: 0,
                bottom: theme.spacing(11),
            },
        },
        infiniteScroll: {
            height: '100%',
        },
        messageContainer: {
            minHeight: 0,
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
        },
        msgItem: {
            //
            background: '#fff',
            // [theme.breakpoints.down('sm')]: {
            //   padding: theme.spacing(1, 0),
            // },
        },
        disabled: {
            opacity: 0.5,
        },
        bottomChatInput: {
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                padding: theme.spacing(1, 2.5, 3),
            },
        },
        topChatHeader: {
            // padding
        },
        zoomIframe: {
            width: '100%',
            height: '100%',
            border: 'none',
        },
    });
});

export default DiscussionLayout;
