/* eslint-disable import/no-cycle */
import { action, Action, thunk, Thunk } from 'easy-peasy';
import { MESSAGE_FETCH_LIMIT, REPLY_FETCH_LIMIT, TDiscussionConfig } from 'Features/Discussion/useDiscussion';
import { TFilter } from 'Models/AppModels/@types';
import DiscussionModel from 'Models/Discussion';
import { TDiscussion, TMessage } from 'Models/Discussion/@types';
import { TRootStore } from 'Stores';
import helpers from 'Utils/helpers';

export interface TDiscussionState {
    discussions: TDiscussion[];
    updateDiscussions: Action<TDiscussionState, { discussion: TDiscussion; action: 'ADD' | 'UPDATE' | 'PUT' | 'DELETE' }>;
    fetchDiscussion: Thunk<
        TDiscussionState,
        { id: string; subject: TDiscussionConfig['subject']; filter?: TFilter },
        any,
        TRootStore,
        Promise<TDiscussion | undefined>
    >;
    fetchMessages: Thunk<TDiscussionState, { config: TDiscussionConfig; filter?: TFilter }, any, TRootStore, Promise<TMessage[]>>;
    appendMessage: Action<TDiscussionState, { message: TMessage; reverse?: boolean }>;

    setPinned: Action<TDiscussionState, { discussionId: string; message: TMessage | undefined }>;
    fetchPinnedMessage: Thunk<TDiscussionState, { config: TDiscussionConfig }>;
    pinUnpinMessage: Thunk<TDiscussionState, { discussionId: string; message: TMessage }>;

    hideMessage: Thunk<TDiscussionState, { discussionId: string; message: TMessage }>;
    deleteMessage: Thunk<TDiscussionState, { discussionId: string; message: TMessage }>;
    editMessage: Thunk<TDiscussionState, { discussionId: string; message: TMessage }>;
    updateSingleMessage: Thunk<TDiscussionState, { discussionId: string; message: Partial<TMessage> & { id: string } }>;

    updateUnreadCountInCommunityDiscussions: Thunk<TDiscussionState, { discussionId: string; unreadMessageCount: number }, any, TRootStore>;

    unlockDiscussion: Thunk<TDiscussion, { discussionId: string; password?: string }>;
}

const DiscussionStore: TDiscussionState = {
    discussions: [],
    updateDiscussions: action((state, payload) => {
        state.discussions = helpers.updateItemList(state.discussions, payload.discussion, payload.action);
    }),
    fetchDiscussion: thunk(async (actions, params) => {
        try {
            const discussion = await DiscussionModel.getSingleDiscussion(params.id, params.filter);
            actions.updateDiscussions({ discussion, action: 'PUT' });
            return discussion;
        } catch (err) {
            throw err;
        }
    }),
    fetchMessages: thunk(async (actions, { config, filter }, { getState }) => {
        const messages = await DiscussionModel.getMessages(config.subject, config.subjectId, config.activeDiscussionId, filter);
        const discussion = getState().discussions.find((i) => i.id === config.activeDiscussionId);
        if (discussion)
            actions.updateDiscussions({
                discussion: {
                    ...discussion,
                    messages: config.reverse
                        ? [...messages.reverse(), ...(discussion.messages || [])]
                        : [...(discussion.messages || []), ...messages],
                    hasMoreMessages: messages.length >= MESSAGE_FETCH_LIMIT,
                },
                action: 'UPDATE',
            });
        return messages;
    }),
    appendMessage: action((state, { message, reverse }) => {
        const discussion = state.discussions.find((i) => i.id === message.discussionId);
        const isReply = !!message.parentMessageId;
        if (discussion) {
            if (isReply) {
                const newDiscussion = { ...discussion };
                if (newDiscussion.pinnedMessage && newDiscussion.pinnedMessage.id === message.parentMessageId) {
                    newDiscussion.pinnedMessage = {
                        ...newDiscussion.pinnedMessage,
                        replies: [...(newDiscussion.pinnedMessage.replies || []), message],
                    };
                }
                state.discussions = helpers.updateItemList(
                    state.discussions,
                    {
                        ...discussion,
                        ...newDiscussion,
                        messages: discussion.messages.map((msg) =>
                            msg.id === message.parentMessageId
                                ? { ...msg, replies: [...(msg.replies || []), message], repliesCount: (msg.repliesCount || 0) + 1 }
                                : msg,
                        ),
                    },
                    'UPDATE',
                );
            } else
                state.discussions = helpers.updateItemList(
                    state.discussions,
                    { ...discussion, messages: reverse ? [...(discussion.messages || []), message] : [message, ...(discussion.messages || [])] },
                    'UPDATE',
                );
        }
    }),

    setPinned: action((state, { discussionId, message }) => {
        state.discussions = state.discussions.map((i) => {
            if (i.id === discussionId) return { ...i, pinnedMessage: message };
            return i;
        });
    }),
    fetchPinnedMessage: thunk(async (actions, { config: { activeDiscussionId, subject, subjectId } }) => {
        const pinnedMessage = await DiscussionModel.getPinnedMessage(subject, subjectId, activeDiscussionId, {
            include: ['user', { relation: 'replies', scope: { include: 'user', limit: REPLY_FETCH_LIMIT } }],
        });
        actions.setPinned({ discussionId: activeDiscussionId, message: pinnedMessage });
    }),
    pinUnpinMessage: thunk(async (actions, { discussionId, message }, { getState }) => {
        const toPin = !message.isPinned;
        await DiscussionModel.pinUnPinMessage(message.id, toPin);
        const discussion = getState().discussions.find((i) => i.id === discussionId);
        if (discussion) {
            if (discussion.pinnedMessage?.id === message.id) {
                actions.updateDiscussions({
                    action: 'UPDATE',
                    discussion: { ...discussion, messages: discussion.messages.map((i) => ({ ...i, isPinned: false })) },
                });
                actions.setPinned({ discussionId, message: undefined });
            } else {
                actions.updateDiscussions({
                    action: 'UPDATE',
                    discussion: {
                        ...discussion,
                        messages: discussion.messages.map((i) => (i.id === message.id ? { ...i, isPinned: true } : { ...i, isPinned: false })),
                    },
                });
                actions.setPinned({ discussionId, message: { ...message, isPinned: true } });
            }
        }
    }),

    hideMessage: thunk(async (actions, { discussionId, message }, { getState }) => {
        const toHide = !message.isHidden;

        await DiscussionModel.hideMessage(discussionId, message.id, toHide);
        const discussion = getState().discussions.find((i) => i.id === discussionId);
        if (discussion) {
            actions.updateDiscussions({
                discussion: DiscussionModel.updateMessageInDiscussion(discussion, { ...message, isHidden: toHide, isPinned: false }),
                action: 'UPDATE',
            });
            if (message.isPinned) actions.setPinned({ discussionId, message: undefined });
        }
    }),
    deleteMessage: thunk(async (actions, { discussionId, message }, { getState }) => {
        await DiscussionModel.deleteMessage(discussionId, message.id);
        const discussion = getState().discussions.find((i) => i.id === discussionId);
        if (discussion) {
            actions.updateDiscussions({
                discussion: DiscussionModel.updateMessageInDiscussion(discussion, {
                    ...message,
                    isDeleted: true,
                    isPinned: false,
                    replies: [],
                    repliesCount: 0,
                }),
                action: 'UPDATE',
            });
            if (message.isPinned) actions.setPinned({ discussionId, message: undefined });
        }
    }),
    editMessage: thunk(async (actions, { discussionId, message }, { getState }) => {
        await DiscussionModel.editMessage(discussionId, message.id, message);
        const discussion = getState().discussions.find((i) => i.id === discussionId);
        if (discussion) {
            actions.updateDiscussions({
                discussion: DiscussionModel.updateMessageInDiscussion(discussion, {
                    ...message,
                    isEdited: true,
                }),
                action: 'UPDATE',
            });
        }
    }),
    updateSingleMessage: thunk((actions, { discussionId, message }, { getState }) => {
        const discussion = getState().discussions.find((i) => i.id === discussionId);
        if (discussion) {
            const msgList = [...discussion.messages];
            for (let i = 0; i < msgList.length; i += 1) {
                if (msgList[i].id === message.id) {
                    actions.updateDiscussions({
                        discussion: DiscussionModel.updateMessageInDiscussion(discussion, { ...msgList[i], ...message }),
                        action: 'UPDATE',
                    });
                    break;
                } else {
                    const rplyMessage = msgList[i].replies?.find((r) => r.id === message.id);
                    if (rplyMessage?.id === message.id) {
                        actions.updateDiscussions({
                            discussion: DiscussionModel.updateMessageInDiscussion(discussion, { ...rplyMessage, ...message }),
                            action: 'UPDATE',
                        });
                        break;
                    }
                }
            }
        }
    }),
    updateUnreadCountInCommunityDiscussions: thunk(async (_a, { discussionId, unreadMessageCount }, { getStoreActions, getStoreState }) => {
        const { community } = getStoreState().CommunityStore;
        if (community)
            getStoreActions().CommunityStore.setCommunity({
                ...community,
                discussions: (community.discussions || []).map((i) => (i.id === discussionId ? { ...i, unreadMessageCount } : i)),
            });
    }),

    unlockDiscussion: thunk(async (actions, { discussionId, password }) => {
        const res = await DiscussionModel.unlockDiscussion(discussionId, password);
        return res;
    }),
};

export default DiscussionStore;
