import { TDropType } from 'Models/Drop/@types';
import { IRewardSetting } from 'Models/Event/Reward/@types';

export interface TRequestWalletConnect {
    message: string;
    nonce: string;
    publicAddress: string;
    email: string;
    isNewUser: boolean;
}

export interface TUserInfo {
    firstName: string;
    lastName?: string;
    email: string;
}

export interface TNonce {
    message: string;
    nonce: string;
    isNewUser: boolean;

    // Ignore DEBUG_ flags.
    DEBUG_publicAddress: string;
    DEBUG_email: string;
}

export enum WalletType {
    METAMASK = 'metamask',
    COINBASE = 'coinbase',
    WALLETCONNECT = 'walletconnect',
    PHANTOM = 'phantom',
    COCRT = 'cocrt',
    PG = 'pg',
    SUI = 'sui',
}

export interface ISuiSignMessageOutput {
    messageBytes: string;
    signature: string;
    publicKey: Uint8Array;
}

export interface INFT {
    token_address: string;
    amount: string;
    name: string;
    symbol: string;
    owner_of: string;
    token_id: string;
    contract_type: string;
    normalized_metadata: INFTMeta;
    image_url?: string;
    description?: string;
    id?: { id: string };
    rewardContract: IRewardContract;
}

export interface IRewardContract {
    dropId: string;
    dropType: TDropType;
    rewardSettings: IRewardSetting;
}

export interface INFTMeta {
    name: string;
    description: string;
    animation_url: string;
    external_link: string;
    image: string;
    attributes: any[];
    media: string;
}
