import AUDIO_DEFAULT_IMG from 'Assets/images/Reward/audio.png';
import VIDEO_DEFAULT_IMG from 'Assets/images/Reward/video.png';
import THREE_D_DEFAULT_IMG from 'Assets/images/Reward/3d.png';
import { Asset, AssetType } from './@types';

export const SUPPORTED_FILE_EXTENSIONS = {
    IMAGE: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'],
    AUDIO: ['mp3', 'wav'],
    VIDEO: ['mp4', 'mov'],
    THREE_D: ['stl', 'glb'],
};

export const checkFileType = (file: File): boolean => {
    const fileName = file.name;
    const fileExtension = fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase(); // Extract file extension and convert to lowercase
    for (const type of Object.values(SUPPORTED_FILE_EXTENSIONS)) {
        if (type.includes(fileExtension)) return true;
    }
    return false;
};

export const getFileType = (fileExtension: string): AssetType => {
    for (const [type, extensions] of Object.entries(SUPPORTED_FILE_EXTENSIONS)) {
        if (extensions.includes(fileExtension)) return type as AssetType;
    }
    return 'IMAGE';
};

export const getFileAccept = (): string => {
    const acceptList: string[] = [];
    for (const extensions of Object.values(SUPPORTED_FILE_EXTENSIONS)) {
        acceptList.push(`.${extensions.join(', .')}`);
    }
    return acceptList.join(',');
};

export const getFileExtension = (fileName: string): string => {
    return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();
};

export const getImageSrc = (asset: Asset | undefined) => {
    if (asset && asset.url) {
        if (asset.type === 'AUDIO') {
            return AUDIO_DEFAULT_IMG;
        } else if (asset.type === 'VIDEO') {
            return VIDEO_DEFAULT_IMG;
        } else if (asset.type === 'THREE_D') {
            return THREE_D_DEFAULT_IMG;
        } else {
            return asset.url;
        }
    } else {
        return '';
    }
};
